* {
  box-sizing: border-box;
}

body,
html {
  font-size: var(--font-size--default);
  color: var(--color-text-default);
  height: 100%;
  margin: 0;
  font-family: var(--font-family);
  line-height: 1.5em;
  overflow: hidden;
}

img {
  width: 100%;
}

a {
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: auto !important;
}

.slick-slide > div {
  height: 100%;
}

/* Hide webpack-dev-server overlay */
#webpack-dev-server-client-overlay {
  display: none !important;
}
